document.addEventListener("DOMContentLoaded", function() {
  const showButtons = document.querySelectorAll('.sign-up-modal-button');
  const signupModal = document.getElementById("sign-up-modal");
  const closeButton = document.getElementById("close-modal");

  closeButton.addEventListener("click", () => {
    signupModal.close();
  });

  showButtons.forEach(showButton => {
    showButton.addEventListener("click", () => {
      signupModal.showModal();
    });
  });
});
