window.add_js_later = function (paths) {
  paths.forEach(function (path) {
    var element = document.createElement("script")
    element.src = path;
    element.setAttribute("type", "text/javascript");
    element.setAttribute("defer", "defer");

    // element = customize_for_third_party(element)

    document.body.addEventListener("load", post_script_load, true)
    document.body.appendChild(element)
  })
}

function post_script_load(event) {
  if (event.target.nodeName === "SCRIPT") {
    const src = event.target.getAttribute("src")

    if (src.includes('eva.min.js')) {
      eva.replace()
    }
  }
}
