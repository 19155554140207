import algoliasearch from 'algoliasearch/lite'
import instantsearch from 'instantsearch.js'
import { searchBox, hits } from 'instantsearch.js/es/widgets'

require('dotenv').config()

const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID || '5T1I1RVUI5',
  process.env.ALGOLIA_API_KEY || 'a40c9e25639570570c6812668a3d33f9'
)

const search = instantsearch({
  indexName: process.env.ALGOLIA_INDEX || 'public_resource_production',
  searchClient,
})

search.addWidgets([
  searchBox({
    container: '#searchbox',
    showSubmit: false,
    placeholder: 'Search our library, e.g. Engineering',
    autofocus: true,
    showReset: false,
    cssClasses: {
      root: 'w-full pr-14',
      input:
        'h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 text-sm',
    },
  }),

  hits({
    container: '#results',
    templates: {
      empty: `
        <div class="py-14 px-6 text-center text-sm sm:px-14">
          <!-- Heroicon name: outline/emoji-sad -->
          <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p class="mt-4 font-semibold text-gray-900">No results found</p>
          <p class="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
        </div>
      `,
      item(hit) {
        const link = getUrl(hit)
        return `
          <li class="hover:bg-opacity-5 hover:bg-gray-900 focus:bg-opacity-5 focus:bg-gray-900 focus:outline-none cursor-default select-none" role="option" tabindex="-1">
            <a href="${link}" class="outline-none focus:bg-opacity-5 focus:bg-gray-900 flex flex-row justify-between items-baseline gap-x-2 px-4 py-2">
              <span class="text-sm">${instantsearch.highlight({
                attribute: 'name',
                highlightedTagName: 'mark',
                hit,
              })}</span>
              <span class="tag ${hit.class.toLowerCase()}">${hit.class}</span>
            </a>
          </li>
        `
      },
    },
  }),
])

search.start()

const selectors = {
  triggers: document.querySelectorAll('.quick-search-button'),
  shortcutTags: document.querySelectorAll('.quick-search-shortcut-tag'),
  overlay: document.querySelector('#searchModalOverlay'),
  input: document.querySelector('#searchbox input'),
  modalOuterContent: document.querySelector('#searchModalOuterContent'), // full page fixed container sitting on top of the overlay
  modalInnerContent: document.querySelector('#searchModalInnerContent'), // just the visual part of the modal
  closeButton: document.querySelector('#searchModalCloseButton'),
  resetButton: document.querySelector('#searchbox button[type="reset"]'),
}

const ACTION_KEY_DEFAULT = ['Ctrl ', 'Control']
const ACTION_KEY_APPLE = ['⌘', 'Command']

document.addEventListener('DOMContentLoaded', function () {
  if (!selectors.triggers) return

  // Open the modal when any elements with the class '.quick-search-button' are clicked
  Array.from(selectors.triggers).forEach((trigger) => {
    trigger.addEventListener('click', openModal)
  })

  let actionKey = null

  if (typeof navigator !== 'undefined') {
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
      actionKey = ACTION_KEY_APPLE
    } else {
      actionKey = ACTION_KEY_DEFAULT
    }
  }

  if (actionKey) {
    Array.from(selectors.shortcutTags).forEach((shortcutTag) => {
      shortcutTag.innerHTML = `
        <abbr title="${actionKey[1]}" class="!no-underline text-sm">
          ${actionKey[0]}
        </abbr> K
      `
    })
  }

  // Close the modal when the overlay is clicked
  selectors.modalOuterContent.addEventListener('click', function (e) {
    const isClickInside = selectors.modalInnerContent.contains(e.target)
    if (!isClickInside) closeModal()
  })

  selectors.closeButton.addEventListener('click', closeModal)
})

function getUrl(result) {
  switch (result.class) {
    case 'Collection':
      return `/publishers/${result.display_org_id}/collections/${result.slug}/`
    case 'Framework':
      return `/publishers/${result.display_org_id}/frameworks/${result.slug}/`
    case 'Skill':
      return `/publishers/${result.display_org_id}/skills/${result.slug}/`
  }
}

function openModal() {
  bodyScrollLock.disableBodyScroll(selectors.modalOuterContent)

  selectors.modalOuterContent.classList.remove('hidden')
  selectors.overlay.classList.remove('hidden')

  selectors.input.focus()
}

function closeModal() {
  bodyScrollLock.enableBodyScroll(selectors.modalOuterContent)

  selectors.modalOuterContent.classList.add('hidden')
  selectors.overlay.classList.add('hidden')

  selectors.resetButton.click()
}

// Close the modal on escape
document.addEventListener('keydown', function (event) {
  if (event.key === 'Escape') closeModal()
})

// Open the modal on cmd + k or ctrl + k depending on the OS
document.addEventListener('keydown', function (event) {
  if (event.key === 'k' && event.metaKey) openModal()
})
