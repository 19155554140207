document.addEventListener('DOMContentLoaded', function(){
  const ebookForm = document.getElementById('ebook-form')
  const ebookFormWrapper = document.getElementById('ebook-form-wrapper')
  const ebookThanksWrapper = document.getElementById('ebook-thanks-wrapper')

  if (ebookForm) {
    ebookForm.addEventListener("submit", function(evt) {
      evt.preventDefault();
      let xhttp = new XMLHttpRequest();
      let formUrl = ebookForm.action
      const email = document.getElementById('ebook-email').value
      const downloadLink = document.getElementById('ebook-download-link').href
      let formData = { fields: [{ name: "email", value: email }] }

      xhttp.open("POST", formUrl, true);
      xhttp.setRequestHeader("accept", "application/json");
      xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhttp.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhttp.send(JSON.stringify(formData));

      ebookFormWrapper.setAttribute("style", "display:none")
      ebookThanksWrapper.setAttribute("style", "display:block")
      ebookThanksWrapper.classList.add("fade-in")

      new jsFileDownloader({ url: downloadLink })
    });
  }
})
