export const positionDatasets = {
  "associate_engineer": [
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    },
    {
      "position": "associate_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Engineer</p>\n      </div>\n    "
    }
  ],
  "software_engineer": [
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Software Engineer</p>\n      </div>\n    "
    }
  ],
  "senior_software_engineer": [
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    },
    {
      "position": "senior_software_engineer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Software Engineer</p>\n      </div>\n    "
    }
  ],
  "engineer_manager": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Engineering Manager</p>\n      </div>\n    ",
      "position": "engineer_manager"
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    },
    {
      "position": "engineer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Engineer Manager</p>\n      </div>\n    "
    }
  ],
  "associate_product_designer": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Designer</p>\n      </div>\n    ",
      "position": "associate_product_designer"
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Designer</p>\n      </div>\n    "
    }
  ],
  "product_designer": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Designer</p>\n      </div>\n    ",
      "position": "product_designer"
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Designer</p>\n      </div>\n    "
    }
  ],
  "senior_product_designer": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Designer</p>\n      </div>\n    ",
      "position": "senior_product_designer"
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_designer",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Designer</p>\n      </div>\n    "
    }
  ],
  "designer_manager": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Design Manager</p>\n      </div>\n    ",
      "position": "designer_manager"
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    },
    {
      "position": "designer_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Designer Manager</p>\n      </div>\n    "
    }
  ],
  "associate_product_manager": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "associate_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Associate Product Manager</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Associate Product Manager</p>\n      </div>\n    ",
      "position": "associate_product_manager"
    }
  ],
  "product_manager": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Manager</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Manager</p>\n      </div>\n    ",
      "position": "product_manager"
    }
  ],
  "senior_product_manager": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 5,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 1 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "position": "senior_product_manager",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Senior Product Manager</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Senior Product Manager</p>\n      </div>\n    ",
      "position": "senior_product_manager"
    }
  ],
  "product_director": [
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Communication",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Communication</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Empathy & Humility",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Empathy & Humility</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Initiative",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Initiative</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Human",
      "axis": "Objectivity & Adaptability",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Objectivity & Adaptability</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Culture and Togetherness",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Culture and Togetherness</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Stakeholder Management",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Stakeholder Management</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Developing Others",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Developing Others</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Hiring and Org Design",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Hiring and Org Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Team Leadership",
      "value": 6,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Team Leadership</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 2 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Leadership",
      "axis": "Design Vision",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Design Vision</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Debugging & Monitoring",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Debugging & Monitoring</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Security",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Security</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Software Architecture",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Software Architecture</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Technical Understanding and Prioritisation",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Technical Understanding and Prioritisation</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Testing",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Testing</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Writing Code",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Writing Code</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Breaking Down Complexity",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Breaking Down Complexity</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Business Context",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Business Context</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Systems Thinking",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Systems Thinking</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "User Focus",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>User Focus</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "position": "product_director",
      "type": "required_level",
      "cat": "Craft",
      "axis": "Visual Design",
      "value": 4,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Visual Design</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Not required for Product Director</p>\n      </div>\n    "
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Delivery",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Delivery</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Insight",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Insight</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    },
    {
      "type": "required_level",
      "cat": "Craft",
      "axis": "Product Strategy",
      "value": 7,
      "tooltip": "\n      <div class='flex flex-grow justify-between items-center'>\n        <h5 class='pr-2'>Product Strategy</h5>\n      </div>\n      <div class='pt-2 mt-2 border-top'>\n        <p class='small'>Level 3 required for Product Director</p>\n      </div>\n    ",
      "position": "product_director"
    }
  ]
}
