import { positionDatasets } from './position-datasets.js';

function radarChart(id, container, data, options) {
  var colors = options.color
  // var levelColors = ["#D9A817", "#38B776", "#267D50"]
  var isMobile = getWidth() >= 768 ? false : true
  var responsivePagePadding = (isMobile == true ? 30 : 0)

  var cfg = {
    w: options.w,				// Width of the circle
    h: options.h,				// Height of the circle
    boost: options.boost,
    margin: options.margin, // The margins of the SVG
    levels: 3,				// How many levels or inner circles should there be drawn
    maxValue: 0, 			// What is the value that the biggest circle will represent
    labelFactor: 1.19, 	// How much farther than the radius of the outer circle should the labels be placed
    wrapWidth: 130, 		// The number of pixels after which a label needs to be given a new line
    opacityArea: 0.5, 	// The opacity of the area of the blob
    dotRadius: 4, 			// The size of the colored circles of each blog
    opacityCircles: 0, 	// The opacity of the circles of each blob
    strokeWidth: 2,		// The width of the stroke around each blob
    transitionTime: 200, // How long things take to transition
    isMobile: isMobile,
    tooltipMaxWidth: 250,
    pagePadding: responsivePagePadding // how much padding the page has outside the div
  };

  // Put all of the options into a variable called cfg
  if ('undefined' !== typeof options) {
    for (var i in options) {
      if ('undefined' !== typeof options[i]) { cfg[i] = options[i]; }
    }
  }

  // If the supplied maxValue is smaller than the actual one, replace by the max in the data
  var maxValue = Math.max(cfg.maxValue, d3.max(data, function(i) { return d3.max(i.map(function(o) { return o.value; })) }));

  var allAxis = (data[0].map(function(i, j) { return i })),	//Names of each axis
    total = allAxis.length,					//The number of different axes
    radius = Math.min(cfg.w/2, cfg.h/2), 	//Radius of the outermost circle
    Format = d3.format(".0f"),			 	//Percentage formatting
    angleSlice = Math.PI * 2 / total;		//The width in radians of each "slice"

  // Scale for the radius
  var rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);

  d3.select(id).select("svg").remove();

  // Initiate the radar chart SVG
  var svg =
    d3
      .select(id)
      .append("svg")
      .attr("width",  cfg.w + cfg.margin.left + cfg.margin.right - cfg.pagePadding)
      .attr("height", cfg.h + cfg.margin.top + cfg.margin.bottom)
      .attr("class", "radar " + id);

  // Append a g element
  var g = svg.append("g").attr("transform", "translate(" + (cfg.w/2 + cfg.margin.left  - cfg.pagePadding/2) + "," + (cfg.h/2 + cfg.margin.top) + ")");

  /////////////////////////////////////////////////////////
  /////////////// Draw the Circular grid //////////////////
  /////////////////////////////////////////////////////////

  // Wrapper for the grid & axes
  var axisGrid = g.append("g").attr("class", "axisWrapper");

  // Draw the background circles
  axisGrid
    .selectAll(".levels")
    .data(d3.range(1,(cfg.levels + 1)).reverse())
    .enter()
    .append("circle")
    .attr("class", "gridCircle")
    .attr("r", function(d, i) { return radius/cfg.levels * d; })
    .style("fill", "transparent")
    .style("stroke", function(d, i) {
      if (i > (cfg.levels - cfg.boost)) {
        return "transparent"
      } else {
        return "#dedede"
      }
    })
    .style("fill-opacity", cfg.opacityCircles)

  /////////////////////////////////////////////////////////
  //////////////////// Draw the axes //////////////////////
  /////////////////////////////////////////////////////////

  // Create the straight lines radiating outward from the center
  var axis = axisGrid.selectAll(".axis").data(allAxis).enter().append("g").attr("class", "axis");

  // Append the lines
  axis
    .append("line")
    .attr("x1", 0)
    .attr("y1", 0)
    .attr("x2", function(d, i) { return rScale(maxValue) * Math.cos(angleSlice * i - (Math.PI/2) - (angleSlice * 0.5)); })
    .attr("y2", function(d, i) { return rScale(maxValue) * Math.sin(angleSlice * i - (Math.PI/2) - (angleSlice * 0.5)); })
    .attr("class", "line")
    .style("stroke-width", "1px")
    .style("stroke", function(d, i) {
      if (d.first_in_cat == true) {
        return '#DEDEDE'
      } else {
        return 'transparent'
      }
    })

  // Append categories
  axis
    .append("text")
    .attr("class", "legend category-label")
    .attr("text-anchor", "middle")
    .attr("dy", "2em")
    .attr("dx", "2em")
    .attr("transform", function(d, i) {
      var rotate = (angleSlice * i * 180 / Math.PI);
      var trans_x = radius * Math.cos(angleSlice * i - Math.PI / 2) * cfg.labelFactor
      var trans_y = radius * Math.sin(angleSlice * i - Math.PI / 2) * cfg.labelFactor

      if (rotate > 90 && rotate <= 270) {
        rotate = rotate + 180
        // Use magic fudge factor to get position of bottom labels just right.
        trans_y = trans_y / 1.125
        trans_x = trans_x / 1.125
      }

      return "translate(" + trans_x + "," + trans_y + ") rotate(" + rotate + ")"
    })
    .text(function(d) {
      if (d.middle_in_cat === true) {
        return d.cat
      }
    })
    .call(wrap, cfg.wrapWidth);

  // Append the labels at each axis
  // Uncomment to see skill names
  // axis
  //   .append("text")
  //   .attr("class", "legend")
  //   .attr("text-anchor", "middle")
  //   .attr("dy", "0.35em")
  //   .attr("x", function(d, i) { return rScale(maxValue * cfg.labelFactor) * Math.cos(angleSlice*i - Math.PI/2); })
  //   .attr("y", function(d, i) { return rScale(maxValue * cfg.labelFactor) * Math.sin(angleSlice*i - Math.PI/2); })
  //   .text(function(d) {return d.name})
  //   .call(wrap, cfg.wrapWidth);

  /////////////////////////////////////////////////////////
  ///////////// Draw the radar chart blobs ////////////////
  /////////////////////////////////////////////////////////

  // The radial line function
  var radarLine = radarLine = d3.lineRadial()
  .curve(d3.curveCardinalClosed.tension(0.3))
  .radius(function(d) { return rScale(d.value); })
  .angle(function(d, i) {	return i*angleSlice; });

  // Create a wrapper for the blobs
  var blobWrapper = g.selectAll(".radarWrapper").data(data).enter().append("g").attr("class", "radarWrapper");

  // Append the backgrounds
  blobWrapper
    .append("path")
    .attr("class", "radarArea")
    .attr("d", function(d, i) { return radarLine(d); })
    .style("fill", function(d, i) { return colors[i]; })
    .style("fill-opacity", cfg.opacityArea)
    .on('mouseover', function(d, i) {
      // Dim all blobs
      d3
        .selectAll(".radarArea")
        .transition()
        .duration(cfg.transitionTime)
        .style("fill-opacity", 0.3); // Opacity on hover for other blob.

      // Bring back the hovered over blob
      d3
        .select(this)
        .transition()
        .style("fill-opacity", 0.7) // Opacity on hover for main blob.
        .duration(cfg.transitionTime)
        .ease(d3.easeQuadInOut);
    })
    .on('mouseout', function() {
      // Bring back all blobs
      d3
        .selectAll(".radarArea")
        .transition()
        .style("fill-opacity", cfg.opacityArea)
        .duration(cfg.transitionTime)
        .ease(d3.easeQuadInOut);
    });

  // Create the outlines
  // Comment this out for borderless blobs
  blobWrapper
    .append("path")
    .attr("class", "radarStroke")
    .attr("d", function(d, i) { return radarLine(d); })
    .style("stroke-width", cfg.strokeWidth + "px")
    .style("stroke", function(d, i) { return colors[i]; })
    .style("fill", "none")

  // Append the circles
  blobWrapper
    .selectAll(".radarCircle")
    .data(function(d, i) { return d; })
    .enter()
    .append("circle")
    .attr("class", "radarCircle")
    .attr("r", cfg.dotRadius)
    .attr("cx", function(d, i) { return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
    .attr("cy", function(d, i) { return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
    .style("fill", function(d, i, j) { return colors[j]; })
    .style("fill-opacity", 1);

  /////////////////////////////////////////////////////////
  //////// Append invisible circles for tooltip ///////////
  /////////////////////////////////////////////////////////

  // Wrapper for the invisible circles on top
  var blobCircleWrapper = g.selectAll(".radarCircleWrapper").data(data).enter().append("g").attr("class", "radarCircleWrapper");

  // Append a set of invisible circles on top for the mouseover pop-up
  blobCircleWrapper
    .selectAll(".radarInvisibleCircle")
    .data(function(d, i) { return d; })
    .enter()
    .append("circle")
    .attr("class", "radarInvisibleCircle")
    .attr("r", cfg.dotRadius)
    .attr("cx", function(d, i) { return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
    .attr("cy", function(d, i) { return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
    .style("stroke-width","2px")
    .style("stroke", blobColor)
    .style("fill", blobColor)
    .style("pointer-events", "all")
    .on("mouseover", function(d, i) {
      d3
        .select(this)
        .transition()
        .style("stroke", "white")
        .attr("r", cfg.dotRadius*1.8)
        .duration(cfg.transitionTime)
        .ease(d3.easeCubicOut);

      var rect = container.getBoundingClientRect()

      tooltip
        .style("top", (event.pageY - rect.top - window.scrollY + 20) + "px")
        .style("left", function() { return cfg.pagePadding === 30 ? "20px" : ((event.pageX - rect.left - cfg.tooltipMaxWidth/2) + "px") })
        .html(d.target.__data__.tooltip)
        .transition()
        .style('opacity', 1)
        .duration(cfg.transitionTime)
        .ease(d3.easeCubicOut);
    })
    .on("mouseout", function() {
      d3
        .select(this)
        .transition()
        .style("stroke", blobColor)
        .attr("r", cfg.dotRadius)
        .duration(cfg.transitionTime)
        .ease(d3.easeCubicIn);

      tooltip
        .transition()
        .style("opacity", 0)
        .duration(cfg.transitionTime)
        .ease(d3.easeCubicIn);
    });

  var tooltip = d3.select(id).append("div").attr("class", "d3-tooltip p-2").style("opacity", 0);

  function wrap(text, width) {
    text.each(function() {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.4, // ems
        y = 0,
        x = 0,
        dy = parseFloat(text.attr("dy")),
        full_string = text.text(),
        prototspan = text.append("tspan").text(full_string), // Make a fake SVG text element so we know how many lines we'll have, so we can shit tall labels.
        lineCount = Math.ceil(prototspan.node().getComputedTextLength() / width),
        tall_label_boost = lineCount * lineHeight * 0.5,
        tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dx", 0).attr("dy", (dy - tall_label_boost) + "em");

      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dx", 0).attr("dy", ((++lineNumber * lineHeight) + dy - tall_label_boost) + "em").text(word);
        }
      }
    });
  }

  function blobColor(d, i) {
    var positionOne = document.getElementById('positionOne').value

    if (d.position == positionOne) {
      return '#FFFFFF'
    } else {
      return 'hsl(0, 0%, 80%)'
    }
  }

  function getWidth() {
    if (self.innerWidth) {
      return self.innerWidth;
    }

    if (document.documentElement && document.documentElement.clientWidth) {
      return document.documentElement.clientWidth;
    }

    if (document.body) {
      return document.body.clientWidth;
    }
  }
}

function groupBy(array, key) {
  return array.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function appendCategoryPosition(datasetsOne, datasetsTwo) {
  let groupedByCategory = groupBy(datasetsOne, 'cat')
  Object.keys(groupedByCategory).forEach(function(category, index) {
    groupedByCategory[category] = groupedByCategory[category].map(function(dataset) { return dataset.axis })
  });

  datasetsOne.forEach(function(dataset) {
    const axes = groupedByCategory[dataset.cat]
    const index = axes.indexOf(dataset.axis)
    dataset.first_in_cat = index === 0
    dataset.middle_in_cat = index === Math.ceil(axes.length / 2) - 1
  })

  return [datasetsOne, datasetsTwo]
}

function filterDatasets(postionOneDatasets, postionTwoDatasets) {
  // Remove datasets where the skill is not required in both positions
  return postionOneDatasets.filter(function(dataset) {
    var axisDataset = postionTwoDatasets.find(element => element.axis == dataset.axis);
    if (dataset.value === 4 && axisDataset.value === 4) {
      return false
    } else {
      return true
    }
  });
}

function data() {
  let positionOne = document.getElementById('positionOne').value
  let positionTwo = document.getElementById('positionTwo').value
  let postionOneDatasets = positionDatasets[positionOne]
  let postionTwoDatasets = positionDatasets[positionTwo]
  let postionOneFilteredDatasets = filterDatasets(postionOneDatasets, postionTwoDatasets)
  let postionTwoFilteredDatasets = filterDatasets(postionTwoDatasets, postionOneDatasets)
  return appendCategoryPosition(postionOneFilteredDatasets, postionTwoFilteredDatasets)
}

function updateRadarChart() {
  var container = document.getElementById('radarChartContainer')

  var margin = { top: 50, right: 50, bottom: 50, left: 50 },
    width = Math.min(600, container.offsetWidth - margin.left - margin.right),
    height = Math.min(600, container.offsetWidth - margin.top - margin.bottom)

  var options = {
    boost: 4,
    color: ["#39C6BA", "#45C639"],
    h: height,
    levels: 9,
    margin: margin,
    maxValue: 9,
    roundStrokes: false,
    w: width,
  }

  radarChart('.radarChartRaw', container, data(), options)
}

function initChart() {
  var dropdownPositionOne = document.getElementById('positionOne')
  var dropdownPositionTwo = document.getElementById('positionTwo')

  if (!dropdownPositionOne || !dropdownPositionTwo) return

  dropdownPositionOne.value = 'associate_engineer'
  dropdownPositionTwo.value = 'software_engineer'

  updateRadarChart()

  dropdownPositionOne.addEventListener("change", function(e) { updateRadarChart() })
  dropdownPositionTwo.addEventListener("change", function(e) { updateRadarChart() })

  window.addEventListener('resize', function(e) {
    document.getElementsByClassName('radarChartRaw')[0].innerHTML = ''
    updateRadarChart()
  });
}

initChart();
